<template>
  <div id="app">
    <NuxtLayout>
      <PageContainer>
        <div class="h-80 w-full sm:w-1/3">
          <Icon name="kh:popcorn" class="h-80 w-auto text-current" />
        </div>
        <div class="w-full space-y-4 px-8 sm:w-2/3">
          <PageHeading
            :title="`${error.statusCode} - ${error.statusMessage}`"
            :tag="PAGE_HEADING_TAG.H1"
          />

          <div v-html="ct('NUXT_ERROR_PAGE_ERROR_TEXT')" />
          <div v-html="ct('NUXT_ERROR_PAGE_SUPPORT_TEXT')" />

          <div class="flex min-w-52 flex-col items-center space-y-3">
            <Button
              class="w-full justify-center"
              :text="t('btn.backHome')"
              @click="handleError"
            />
            <Button
              :theme="BUTTON_THEME.SECONDARY"
              class="w-full justify-center"
              :text="t('btn.support')"
            />
          </div>
        </div>
      </PageContainer>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { PAGE_HEADING_TAG } from '@base/constants/page'
import { BUTTON_THEME } from '@base/constants/button'

interface Props {
  error: {
    statusCode: number
    statusMessage: string
  }
}

defineProps<Props>()

const { ct, fetchCinemaStrings } = useCinemaStrings()
const { overrideCreateElement } = useConsentManager()
const { t } = useI18n()

await fetchCinemaStrings({
  keys: ['NUXT_ERROR_PAGE_ERROR_TEXT', 'NUXT_ERROR_PAGE_SUPPORT_TEXT'],
})

if (import.meta.client) {
  overrideCreateElement()
}

const handleError = () => clearError({ redirect: '/' })

defineOptions({
  name: 'ErrorPage',
})
</script>

<i18n>
de:
  configNotFound: "CmsConfig nicht gefunden"
  cinemaNotFound: "Kino nicht gefunden"
  pageNotFound: "Seite nicht gefunden"
  movieNotFound: "Film nicht gefunden"
  cityNotFound: "Stadt nicht gefunden"
  genreNotFound: "Genre nicht gefunden"
  personNotFound: "Person nicht gefunden"
  accessDenied: "Zugriff verweigert"
  btn:
    backHome: "Zurück zur Startseite"
    support: "kinoheld Support"
es:
  configNotFound: "CmsConfig no encontrada"
  cinemaNotFound: "Cine no encontrada"
  pageNotFound: "Página no Disponible"
  movieNotFound: "Película no encontrada"
  cityNotFound: "Ciudad no encontrada"
  genreNotFound: "Género no encontrado"
  personNotFound: "Persona no encontrada"
  accessDenied: "Acceso denegado"
  btn:
    backHome: "Volver a la página de inicio"
    support: "Soporte de kinoheld"

</i18n>
