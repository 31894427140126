import validate from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/pages/runtime/validate.js";
import cms_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/cms/middleware/cms.global.ts";
import auth_45token_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/account/middleware/auth-token.global.ts";
import backend_45token_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/account/middleware/backend-token.global.ts";
import affiliate_45and_45referrer_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/base/middleware/affiliate-and-referrer.global.ts";
import consent_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/base/middleware/consent.global.ts";
import utm_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/base/middleware/utm.global.ts";
import widget_45mode_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/portal/middleware/widget-mode.global.ts";
import redirects_45global from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/apps/kinoheld/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_g2mz2zxdhz5ztp7p6r4awebgzi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cms_45global,
  auth_45token_45global,
  backend_45token_45global,
  affiliate_45and_45referrer_45global,
  consent_45global,
  utm_45global,
  widget_45mode_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/forge/deployments/kinoheld/live/2025-02-13_10-34-22_22eb119/layers/builder/middleware/admin.ts")
}